@import "../../../../css/variables/allvariables";

.telemed-th-container {
  position: relative;
  background-color: $white-bg;
  margin-top: 30px;
  //   overflow: scroll;
  padding: 20px;
  display: flex;
  border: 1px solid #{$border-color};
  border-bottom: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .telemed-th-p1 {
    font-size: 18px;
    font-weight: 700;
    // flex-grow: 1;
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;
    color: $primary-color;
    // word-break: break-all;
  }
  .telemed-th-div1 {
    // margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    .telemed-th-label1 {
      margin-left: 20px;
      // position: relative;
      border: 1px solid #{$border-color};
      border-radius: 3px;
      padding: 6px 10px;
      background-color: $border-color;
      // cursor: pointer;
      display: flex;
      .telemed-th-inp1 {
        font-size: 14px;
        color: black;
        font-weight: 400;
        width: 250px;
        font-family:'Lato', sans-serif;
        background: none;
        border: none;
        outline: none;
        &::placeholder {
          font-size: 14px;
          font-weight: 400;
          color: $placeholder-color;
        }
      }
      .telemed-th-icon1 {
        position: relative;
        top: 2px;
        font-size: 16px;
        color: $text-color;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 1024px) {
  .telemed-th-container {
    .telemed-th-p1 {
      font-size: 16px;
      font-weight: 700;
    }
    .telemed-th-div1 {
      .telemed-th-label1 {
        display: flex;
        .telemed-th-inp1 {
          font-size: 14px;
          &::placeholder {
            font-size: 14px;
          }
        }
        .telemed-th-icon1 {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .telemed-th-container {
    padding: 14px;
    flex-direction: column;
    // align-items: flex-start;
    .telemed-th-p1 {
      font-size: 16px;
      font-weight: 700;
      text-align: center;
    }
    .telemed-th-div1 {
      margin-top: 14px;
      .telemed-th-label1 {
        margin-left: 0px;
        .telemed-th-inp1 {
          font-size: 14px;
          width: 160px;
          &::placeholder {
            font-size: 14px;
          }
        }
        .telemed-th-icon1 {
          font-size: 16px;
        }
      }
    }
  }
}
