@import "../../css/variables/allvariables";


.Modal {
  display: flex;
  justify-content: center;
  align-items: center;

  .parentPrivacyimf {
    height: 300px;
    width: 40%;
    background-color: $white-bg;
    border-radius: 5px;
    opacity: 0.9;
    outline: none;
    .Privacyimf {
      height: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: column;
      h1 {
        font-size: 25px;
        font-family: "Roboto";
        font-weight: 500;
      }
      p {
        width: 70%;
        text-align: center;
        font-size: 17px;
        font-family: "Roboto";
      }
      .PrivacyimfNextbtn {
        width: 30%;
        background-color: $second-color;
        border-radius: 20px;
        color: $white-color;
      }
    }
  }
}



@media (max-width: 870px) {
  .Modal {
    display: flex;
    justify-content: center;
    align-items: center;

    .parentPrivacyimf {
      height: 300px;
      width: 90%;
      background-color: $white-bg;
      border-radius: 5px;
      opacity: 0.9;
      outline: none;
      .Privacyimf {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h1 {
          margin-top: 13px;
          font-size: 25px;
          font-family: "Roboto";
          font-weight: 500;
        }
        p {
          margin-top: 13px;
          width: 90%;
          text-align: center;
          font-size: 17px;
          font-family: "Roboto";
        }
        .PrivacyimfNextbtn {
          margin-top: 20px;
          width: 40%;
          background-color: $second-color;
          border-radius: 20px;
          color: $white-color;
        }
      }
    }
  }
}


@media (max-width: 570px) {
  .Modal {
    display: flex;
    justify-content: center;
    align-items: center;

    .parentPrivacyimf {
      height: 300px;
      width: 90%;
      background-color: $white-bg;
      border-radius: 5px;
      opacity: 0.9;
      outline: none;
      .Privacyimf {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h1 {
          margin-top: 13px;
          font-size: 25px;
          font-family: "Roboto";
          font-weight: 500;
        }
        p {
          margin-top: 13px;
          width: 90%;
          text-align: center;
          font-size: 17px;
          font-family: "Roboto";
        }
        .PrivacyimfNextbtn {
          margin-top: 20px;
          width: 40%;
          background-color: $second-color;
          border-radius: 20px;
          color: $white-color;
        }
      }
    }
  }
}

@media (max-width: 470px) {
  .Modal {
    display: flex;
    justify-content: center;
    align-items: center;

    .parentPrivacyimf {
      height: 300px;
      width: 90%;
      background-color: $white-bg;
      border-radius: 5px;
      opacity: 0.9;
      outline: none;
      .Privacyimf {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h1 {
          margin-top: 13px;
          font-size: 25px;
          font-family: "Roboto";
          font-weight: 500;
        }
        p {
          margin-top: 13px;
          width: 90%;
          text-align: center;
          font-size: 17px;
          font-family: "Roboto";
        }
        .PrivacyimfNextbtn {
          margin-top: 20px;
          width: 40%;
          background-color: #a5abb1;
          border-radius: 20px;
          color: white;
        }
      }
    }
  }
}
@media (max-width: 440px) {
  .Modal {
    display: flex;
    justify-content: center;
    align-items: center;

    .parentPrivacyimf {
      height: 300px;
      width: 90%;
      background-color: white;
      border-radius: 5px;
      opacity: 0.9;
      outline: none;
      .Privacyimf {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h1 {
          margin-top: 13px;
          font-size: 25px;
          font-family: "Roboto";
          font-weight: 500;
        }
        p {
          margin-top: 13px;
          width: 90%;
          text-align: center;
          font-size: 17px;
          font-family: "Roboto";
        }
        .PrivacyimfNextbtn {
          margin-top: 20px;
          width: 40%;
          background-color: #0983fc;
          border-radius: 20px;
          color: white;
        }
      }
    }
  }
}
@media (max-width: 375px) {
  .Modal {
    display: flex;
    justify-content: center;
    align-items: center;

    .parentPrivacyimf {
      height: 300px;
      width: 90%;
      background-color: white;
      border-radius: 5px;
      opacity: 0.9;
      outline: none;
      .Privacyimf {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h1 {
          margin-top: 13px;
          font-size: 25px;
          font-family: "Roboto";
          font-weight: 500;
        }
        p {
          margin-top: 13px;
          width: 90%;
          text-align: center;
          font-size: 17px;
          font-family: "Roboto";
        }
        .PrivacyimfNextbtn {
          margin-top: 20px;
          width: 40%;
          background-color: #0983fc;
          border-radius: 20px;
          color: white;
        }
      }
    }
  }
}
