.tl-container {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}
    .tl {
      border: 3px solid #ffffff;
      border-radius: 50%;
      border-top: 3px solid #0074E8;
      width: 50px;
      height: 50px;
      -webkit-animation: spin 1s linear infinite; /* Safari */
      animation: spin 1s linear infinite;
    }
    .tl-p1 {
      position: relative;
      margin-top: 20px;
      font-size: 12px;
      font-weight: 500;
      color: black;
    }
  
    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
  
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  