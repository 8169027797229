@import "../variables/allvariables";


/** @format */

.VideoSection {
  position: relative;
  min-height: 100vh;
  // max-height: 100vh;
  height: 100%;
  // flex: 1;
  // height: 640px;
  width: 100%;
  // padding-left: 70px;
  background-color: $disable-bg;

  //   justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  video {
    object-fit: cover;
    // max-width: 2500px;
    // min-width: 500px;
    width: 100%;
    // background-color: gold;
    height: 100vh;
    //   margin-top: -150px;
    // min-width: 500px;
    // min-height: 50vh ;
  }
  .Profile {
    position: absolute;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 120px;
    img {
      height: 170px;
      width: 170px;
    }
    p {
      font-size: 28px;
      font-weight: normal;
      color: $white-color;
    }
  }
  .Buttons {
    display: flex;
    justify-content: space-between;
    width: 25%;
    position: absolute;
    bottom: 10%;
    // background-color: red;
    // margin-top: 100px;

    .btn1 {
      outline: none;
      display: flex;
      border: none;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 60px;
      width: 60px;
      border-radius: 100%;
      background-color: $white-bg;
    }
  }
  .participent_cam {
    position: absolute;
    height: 220px;
    width: 220px;
    right: 20px;
    top: 20px;
    // border:2px solid white;
    // border-radius: 10px;
    // overflow: hidden;
    padding: 6px 0px;
    background-color: $primary-color;
    -webkit-box-shadow: 2px 4px 5px -1px #{$border-color};
    -moz-box-shadow: 2px 4px 5px -1px #{$border-color};
    box-shadow: 2px 4px 5px -1px #{$border-color};
    .CallPermission {
      // position: absolute;
      // right: 50px;
      // top: 120px;
      width: 100%;
      height: 100%;
      background: $primary-color 0% 0% no-repeat padding-box;
      opacity: 1;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      // padding: 16px;
      // justify-content: space-between;
      // padding: 10px;
      // align-items: center;
      align-self: center;
      color: $text-color;
      -webkit-box-shadow: 2px 4px 5px -1px #{$border-color};
      -moz-box-shadow: 2px 4px 5px -1px #{$border-color};
      box-shadow: 2px 4px 5px -1px #{$border-color};
      .cp_image,
      .cp_image_red {
        height: 40px;
        width: 40px;
        align-self: center;
        margin-top: 40px;
      }
      .cp_p1,
      .cp_p1_red {
        margin-top: 16px;
        width: 90%;
        align-self: center;
        text-align: center;
        color: $white-color;
        font-weight: normal;
        font-family: 'Lato', sans-serif;
      }

      .cp_webcam_parent {
        background-color:$white-bg;
        border-radius: 16px;
        height: 100%;
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .cp_webcam {
          border-radius: 10px;
          height: 100%;
          width: 100%;
          margin: 0;
          padding: 0;
          object-fit: cover;
        }
        .TedstVidecCallbtn {
          border-radius: 20px;
          width: 150px;
          background-color: $second-color;
          color: $white-color;
        }
      }
      // .cp_webcam {
      //   height: 100%;
      //   width: 100%;
      //   margin: 0;
      //   padding: 0;
      //   object-fit: cover;
      // }

      .get-permission-btn {
        width: 90%;
        align-self: center;
        margin-top: 16px;
        padding: 12px;
        border-radius: 5px;
        // height: 50px;
        color: $white-color;
        font-weight: bold;
        border: none;
        outline: none;
        transition: 0.2s;
        background-color: $theme-color;
        &:hover {
          opacity: 0.9;
          cursor: pointer;
        }
      }
    }
  }
  .participent_patientDetails {
    position: absolute;
    top: 20px;
    left: 20px;

    .participent_patientDetails_p1 {
      font-family: 'Lato', sans-serif;
      font-size: 16px;
      font-weight: 700;
      color: $white-color;
      background-color: $second-color;
      padding: 10px 30px;
      border-radius: 10px;
      width: fit-content;
      cursor: pointer;
    }
    .participent_patientDetails_content {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      width: 360px;
      background-color: $white-bg;
      padding: 10px 20px 30px;
      border-radius: 10px;
      -webkit-box-shadow: 2px 4px 5px -1px rgba(0, 0, 0, 0.36);
      -moz-box-shadow: 2px 4px 5px -1px rgba(0, 0, 0, 0.36);
      box-shadow: 2px 4px 5px -1px rgba(0, 0, 0, 0.36);
      .participent_patientDetails_Icon {
        padding: 3px;
        color: $white-color;
        background-color: $second-color;
        font-weight: 400;
        font-size: 16px;
        align-self: flex-end;
        border-radius: 100px;
        cursor: pointer;
      }
      .participent_patientDetails_h1 {
        margin-top: 10px;
        font-size: 20px;
        font-weight: 700;
        font-family: 'Lato', sans-serif;
        color: $primary-color;
        margin-bottom: 10px;
      }
      .participent_patientDetails_appInfo {
        .participent_patientDetails_appContent {
          display: flex;
          margin-top: 5px;
          .participent_patientDetails_p2 {
            font-size: 18px;
            font-weight: 400;
            color: $primary-color;
            font-family: 'Lato', sans-serif;
            width: 40%;
          }
          .participent_patientDetails_p3 {
            font-size: 18px;
            font-weight: 300;
            color: $text-color;
            font-family: 'Lato', sans-serif;
            word-break: break-all;
            width: 60%;
          }
        }
      }
    }
    .participent_patientDetails_contentHide {
      display: none;
    }
  }
}

@media only screen and (max-width: 999px) {
  .VideoSection {
    .participent_cam {
      height: 100px;
      width: 100px;
      .CallPermission {
        .cp_image,
        .cp_image_red {
          height: 16px;
          width: 16px;
          margin-top: 12px;
        }
        .cp_p1,
        .cp_p1_red {
          margin-top: 10px;
          font-size: 10px;
          width: 100%;
        }
        .get-permission-btn {
          width: 92%;
          margin-top: 8px;
          padding: 6px;
          font-size: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  /** @format */

  .VideoSection {
    .Profile {
      img {
        height: 100px;
        width: 100px;
      }
      p {
        font-size: 24px;
      }
    }
    .Buttons {
      display: flex;
      justify-content: space-between;
      width: 50%;
      position: absolute;
      bottom: 15%;
      // margin-top: 100px;

      .btn1 {
        height: 50px;
        width: 50px;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  /** @format */

  .VideoSection {
    .Profile {
      img {
        height: 100px;
        width: 100px;
      }
      p {
        font-size: 24px;
      }
    }
    .Buttons {
      display: flex;
      justify-content: space-between;
      width: 50%;
      position: absolute;
      bottom: 50px;
      // margin-top: 100px;

      .btn1 {
        height: 50px;
        width: 50px;
      }
    }
    .participent_patientDetails {
      .participent_patientDetails_p1 {
        font-size: 14px;
        padding: 10px 14px;
      }
      .participent_patientDetails_content {
        margin-top: 14px;
        width: 260px;
        padding: 10px;
        .participent_patientDetails_Icon {
          font-size: 16px;
        }
        .participent_patientDetails_h1 {
          font-size: 16px;
          margin-bottom: 5px;
        }
        .participent_patientDetails_appInfo {
          .participent_patientDetails_appContent {
            margin-top: 5px;
            .participent_patientDetails_p2 {
              width: 35%;
              font-size: 14px;
            }
            .participent_patientDetails_p3 {
              width: 65%;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  /** @format */

  .VideoSection {
    padding: 0;
    .Buttons {
      display: flex;
      justify-content: space-between;
      width: 55%;
      position: absolute;
      bottom: 50px;
      // margin-top: 100px;

      .btn1 {
        outline: none;
        display: flex;
        border: none;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 50px;
        width: 50px;
        border-radius: 100%;
        background-color: $white-bg;
      }
    }
  }
}

@media only screen and (max-width: 470px) {
  /** @format */

  .VideoSection {
    .Profile {
      img {
        height: 100px;
        width: 100px;
      }
      p {
        font-size: 24px;
      }
    }
    .Buttons {
      display: flex;
      justify-content: space-between;
      width: 50%;
      position: absolute;
      bottom: 18%;
      // margin-top: 100px;

      .btn1 {
        height: 50px;
        width: 50px;
      }
    }
  }
}
@media only screen and (max-width: 440px) {
  /** @format */

  .VideoSection {
    .Profile {
      img {
        height: 100px;
        width: 100px;
      }
      p {
        font-size: 24px;
      }
    }
    .Buttons {
      display: flex;
      justify-content: space-between;
      width: 50%;
      position: absolute;
      bottom: 18%;
      // margin-top: 100px;

      .btn1 {
        height: 50px;
        width: 50px;
      }
    }
  }
}
@media only screen and (max-width: 414px) {
  /** @format */

  .VideoSection {
    .Profile {
      img {
        height: 100px;
        width: 100px;
      }
      p {
        font-size: 24px;
      }
    }
    .Buttons {
      display: flex;
      justify-content: space-between;
      width: 50%;
      position: absolute;
      bottom: 18%;
      // margin-top: 100px;

      .btn1 {
        height: 50px;
        width: 50px;
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  /** @format */

  .VideoSection {
    .Profile {
      img {
        height: 100px;
        width: 100px;
      }
      p {
        font-size: 24px;
      }
    }
    .Buttons {
      display: flex;
      justify-content: space-between;
      width: 50%;
      position: absolute;
      bottom: 18%;
      // margin-top: 100px;

      .btn1 {
        height: 50px;
        width: 50px;
      }
    }
  }
}
