// ********************************************************** //
// ********************************************************** //
// ******************* Theme color ************************** //
// ********************************************************** //
// ********************************************************** //
$theme-color: #10BA77;
$white-bg: #FFFFFF;
$white-color: #FFFFFF;
$primary-color: #00234B;
$header-bg: #142F62;
$second-color: #0074E8;
$disable-bg: grey;
$link-color: #0057AF;
$text-color: #526071;
$label-color: #465F7C;
$placeholder-color: #AEB4BC;
$border-color: #D0D7E0;
$page-bg: #F5F7F8;
$error-color: #B00020;
$orange-color: #FC9B40;



