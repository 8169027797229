@import "../../../../css/variables/allvariables";

#Parent_test_modal {
  #TestModal {
    .header {
      //   height: 70px;
      width: 100%;
      background-color: $header-bg;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      padding-left: 20px;
      padding-right: 20px;
      p {
        font-family: 'Lato', sans-serif;
        font-size: 19px;
        color: $white-color;
        font-weight: bold;
      }
    }
    .TestCallGraph {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 30px;
      h1 {
        font-weight: 400;
        font-family: 'Lato', sans-serif;
        color: $primary-color;
        span {
          font-weight: bold;
        }
      }
      p {
        font-family: 'Lato', sans-serif;
        color: #5d5d5d;
      }
      .graph {
        margin-top: 20px;
        // height: 150px;

        overflow: hidden;
        width: 60%;
        border: 1px solid #{$primary-color};

        // background-color: #3f3d56;
      }
    }
  }
}
