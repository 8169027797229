@import "../variables/allvariables";

.loader-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .loader {
    // border: 3px solid #{$border-color};
    border-radius: 50%;
    border-top: 3px solid #{$second-color};
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }
  .loader-p1{
    position: relative;
    margin-top: 20px;
    font-size: 12px;
    font-weight: 500;
    color: $text-color;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
