@import "../variables/allvariables";
.navigation {
  position: absolute;
  // top: 8%;
  left: 0;
  width: 3.4vw;
  width: 50px;
  height: 92vh;
  background-color: $primary-color;
  .mb-div1 {
    position: relative;
    top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // border: 1px solid White;
    .mb-icon img {
      display: none !important;
    }
    .mb-icon {
      color: $white-color;
      width: 100%;
      padding: 10px 0;
      text-align: center;
      font-size: 20px;
      margin-bottom: 5px;
      cursor: pointer;
      // &:hover {
      //   border-left: 3px solid white;
      //   background-color: rgba(255, 255, 255, 0.1);
      // }
    }
    .mb-icon1 {
      color:  $white-color;
      width: 100%;
      padding: 10px 0;
      text-align: center;
      font-size: 20px;
      margin-bottom: 5px;
      border-left: 3px solid #{$white-color};
      background-color: $page-bg;
    }
  }
}
.navigation2 {
  position: absolute;
  // top: 8%;
  left: 0;
  width: 3.4vw;
  width: 70px;
  height: 100vh;
  min-height: 100vh;
  max-width: 180vh;
  background-color: $primary-color;
  .mb-div1 {
    position: relative;
    top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // border: 1px solid White;
    .mb-icon img {
      display: none !important;
    }
    .mb-icon {
      color: $white-color;
      width: 100%;
      padding: 10px 0;
      text-align: center;
      font-size: 20px;
      margin-bottom: 5px;
      cursor: pointer;
      // opacity: 100%;
      // &:hover {
      //   border-left: 3px solid white;
      //   background-color: rgba(255, 255, 255, 0.1);
      // }
    }
    .mb-icon1 {
      color: $white-color;
      width: 100%;
      padding: 10px 0;
      text-align: center;
      font-size: 19px;
      margin-bottom: 5px;
      opacity: 0.9;
      border-left: 5px solid $error-color;
      background-color: $page-bg;
    }
  }
}



@media only screen and (max-width: 500px) {
  .navigation2 {
    display: none;
  }
}