@import url("https://fonts.googleapis.com/css2?family=Encode+Sans:wght@400;700&display=swap");
@import "../../../../css/variables/allvariables";

.tdc_logoContainer {
  //   background-color: #FBFBFB;
  // background-color: gold;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Lato', sans-serif;
  .tdc_imageContainer {
    // width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: -20px;
    // background-color: gold;
    img {
      width: 90%;
    }
  }
  .tdc_logoContent {
    margin-left: 10px;
    text-align: center;

    .tdc_p1 {
      font-size: 14px;
      font-weight: 700;
      color: $primary-color;
    }
    .tdc_p2 {
      font-size: 12px;
      font-weight: 400;
      color: $primary-color;
    }
  }
}

.tb_imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: gold;
  margin-right: 30px;
  margin-left: -10px;
  img {
    width: 90%;
  }
}
.tb_notificationIcon {
}
.tb_profileContainer {
  // background-color: gold;
  position: relative;
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .tb_para1 {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 700;

    margin-right: 30px;
  }

  .tb_imageContainer1 {
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: gold;
    height: 40px;
    width: 40px;
    border-radius: 100px;
    overflow: hidden;
    // border: 1px solid black;
    img {
      height: 100%;
      width: 100%;
    }
  }

  .tb_profilePopUp {
    z-index: 2;
    position: absolute;
    background-color: $white-bg;
    // padding: 5px 0;
    // background-color: gold;
    border-radius: 10px;
    box-shadow: 0px 0px 8px #{$border-color};
    width: 200px;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .tb_ClearIcon {
      
      color: $primary-color;
      align-self: flex-end;
      cursor: pointer;
      // background-color: gold;
      padding: 10px 10px;
    }

    .tb_imageContainer1 {
      display: flex;
      align-items: center;
      justify-content: center;
      // background-color: gold;
      height: 40px;
      width: 40px;
      border-radius: 100px;
      overflow: hidden;
      margin-top: 5px;
      // border: 1px solid black;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .tb_Name {
      font-family: 'Lato', sans-serif;
      font-size: 16px;
      font-weight: 700;
      color: black;
      margin-top: 10px;
      text-align: center;
    }
    hr {
      // margin-top: 3px;
      width: 100%;
      background-color: rgb(201, 201, 201);
      border: none;
      height: 1px;
    }
    .tb_para2 {
      font-family: 'Lato', sans-serif;
      font-size: 16px;
      font-weight: 400;
      color: $primary-color;
      margin-right: 30px;
      padding: 10px;
      align-self: flex-start;
      cursor: pointer;
      width: 100%;
    }
    .tb_para3 {
      font-family: 'Lato', sans-serif;
      font-size: 16px;
      font-weight: 400;
      color: $primary-color;
      width: 100%;
      margin-right: 129px;
      transition: all 0.2s linear;
      padding: 10px;
      align-self: flex-start;
      cursor: pointer;
      width: 100%;
      &:hover {
        background-color: $page-bg;
      }
    }
  }
  .tb_profilePopUp_hide {
    display: none;
  }
}

@media (max-width: 768px) {
  .tb_imageContainer {
    // background-color: gold;
    margin-right: 5px;
    margin-left: -8px;
    img {
      width: 70%;
    }
  }
  .tb_notificationIcon {
    // height: ;
    // background-color: gold;
  }
  .tb_profileContainer {
    // background-color: gold;
    position: relative;
    .tb_para1 {
      font-family: 'Lato', sans-serif;
      font-size: 14px;
      font-weight: 700;
      margin: 0 20px 0px 10px;
    }
    .tb_imageContainer1 {
      display: flex;
      align-items: center;
      justify-content: center;
      // background-color: gold;
      height: 30px;
      width: 30px;
      border-radius: 100px;
      overflow: hidden;
      // border: 1px solid black;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}

@media (max-width: 360px) {
  .tb_imageContainer {
    // background-color: gold;
    margin-right: 5px;
    margin-left: -8px;
    img {
      width: 60%;
    }
  }
  .tb_notificationIcon {
    // height: ;
    // background-color: gold;
  }
  .tb_profileContainer {
    // background-color: gold;
    position: relative;
    .tb_para1 {
      font-family: 'Lato', sans-serif;
      font-size: 12px;
      font-weight: 700;
      margin: 0 14px 0px 2px;
    }
    .tb_imageContainer1 {
      display: flex;
      align-items: center;
      justify-content: center;
      // background-color: gold;
      height: 30px;
      width: 30px;
      border-radius: 100px;
      overflow: hidden;
      // border: 1px solid black;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}
