@import "../../../../css/variables/allvariables";

.confirmation_SuccessModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $white-bg;
  padding: 20px;
  border-radius: 20px;
  outline: none;
  .confirmation_btnContainer{
    display: flex;
    // background-color: red;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  img {
    height: 120px;
    width: 120px;
  }
  h1 {
    margin-top: 10px;
    color: $primary-color;
    padding: 10px;
    font-size: 18px;
    font-weight: 400;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
    text-align: center;
  }
  p {
    padding: 10px;
    text-align: center;
    font-size: 18px;
    color: $text-color;
    font-family: 'Lato', sans-serif;
  }
  .SuccessModal-BTN {
    margin: 10px;
    background-color: $second-color;
    width: 40%;
    padding: 0.5em;
    border: 1px solid #{$second-color};
    border-radius: 10px;
    color: $white-color;
    font-weight: bold;
    font-family:'Lato', sans-serif;
    &:hover {
      color: $second-color;
    }
  }
}
