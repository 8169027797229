@import "../variables/allvariables";

.unsupportWrappre {
  position: absolute;
  height: 110vh;
  width: 100vw;
  background-color: $white-bg;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  //   justify-content: center;
  //   align-items: center;
  flex-direction: column;
  .cp_image_red {
    margin-top: 100px;
    height: 400;
    align-self: center;
  }
  p {
    margin-top: 30px;
    align-self: center;
    font-size: 25px;
    color: $error-color;
  }
  span {
    font-family: "Varela Round", sans-serif;
    text-align: center;
    margin-top: 30px;
    align-self: center;
  }
  button {
    margin-top: 30px;
    align-self: center;
    width: 50%;
    border: none;
    padding: 10px;
    color: $white-color;
    background-color: $theme-color;
    display: flex;
    justify-content: center;
    -webkit-box-shadow: 4px 6px 12px 0px #{$border-color};
-moz-box-shadow: 4px 6px 12px 0px #{$border-color};
box-shadow: 4px 6px 12px 0px #{$border-color};
    span {
        padding-left: 10px;
      margin-top: -2px;
    }
  }
}
