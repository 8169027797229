@import "../variables/allvariables";
.bl-btn1 {
  position: absolute;
  bottom: 26px;
  right: 26px;
  font-size: 14px;
  font-weight: 500;
  color: $white-color;
  padding: 0.7em 2em;
  // background-color: #ff8103;
  border: none;
  outline: none;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 1px 6px #{$border-color};
  &:disabled {
    background-color: $disable-bg;
  }
}
.bl-icon1 {
  margin-left: -12px;
  margin-right: 8px;
}
