
@import "../variables/allvariables";

.containeer {
  height: 100%;
  width: 100%;
  min-height: 91vh;
  //   min-height: 100vh;
  //   max-height: 190vh;
  padding: 0px 30px;
  // background-color: #eeeeee;
  h1 {
    font-size: 32px;
    font-weight: 700;
    font-family: 'Lato', sans-serif;
    color: $primary-color;
    // font: Regular 28px/40px Source Sans Pro;
    // margin-left: 100px;
    // padding-top: 20px;
  }
  .QuestionBox {
    margin-top: 40px;
    // margin-left: 100px;
    padding: 20px;
    width: 90%;
    border-radius: 20px;
    // height: 458px;
    background: $white-bg;
    box-shadow: 0px 2px 6px #{$border-color};
    h4 {
      text-align: left;
      font-size: 24px;
      font-family: 'Lato', sans-serif;
      font-weight: normal;
      // font: Regular 24px/25px Source Sans Pro;
      letter-spacing: 0px;
      color: $primary-color;
      // opacity: 1;
    }
    .Question-num {
      padding: 16px 0px;
      h3 {
        font-size: 18px;
        font-family: 'Lato', sans-serif;
        font-weight: bold;
        // font: Regular 24px/25px Source Sans Pro;
        letter-spacing: 0px;
        color: $primary-color;
      }
      p {
        margin-top: 10px;
        font-size: 18px;
        font-family: 'Lato', sans-serif;
        font-weight: normal;
        // font: Regular 24px/25px Source Sans Pro;
        letter-spacing: 0px;
        color: $text-color;
      }
    }
  }
  .Troubleshoot {
    position: relative;
    top: 70px;
    left: 100px;
    width: 1311px;
    height: 411px;
    background: $white-bg 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #{$border-color};
    opacity: 1;
    h5 {
      padding-left: 40px;
      padding-top: 30px;
      font-size: 31px;
      font-family: 'Lato', sans-serif;
      font-weight: normal;
      // font: Regular 24px/25px Source Sans Pro;
      letter-spacing: 0px;
      color: $primary-color;
    }
    .typeQues {
      display: flex;
      flex-direction: column;
      width: 55%;
      margin-top: 20px;
      margin-left: 40px;
      input {
        background: $white-color;
        border: 1px solid $border-color;
        border-radius: 4px;
        width: 350px;
        padding: 15px;
        outline: none;
        opacity: 1;
      }
      textarea {
        margin-top: 20px;
        background:  $white-color;
        border: 1px solid $border-color;
        border-radius: 4px;
        padding: 15px;
        outline: none;
        opacity: 1;
        width: 720px;
        height: 119px;
      }
      .btn-submit {
        display: flex;
        justify-content: flex-end;
        button {
          outline: none;
          color: $white-color;
          margin-top: 20px;
          height: 40px;
          width: 140px;
          background: $disable-bg 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 6px #{$border-color};
          border-radius: 4px;
          opacity: 1;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .containeer {
    h1 {
      font-size: 24px;

      margin-left: 90px;
    }
    .QuestionBox {
      width: 80%;

      margin-left: 90px;
      h4 {
        font-size: 22px;
      }
      .Question-num {
        h3,
        P {
          font-size: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .containeer {
    padding: 20px;
    h1 {
      margin-top: 0;
      font-size: 22px;

      margin-left: 0px;
    }
    .QuestionBox {
      width: 100%;
      margin-top: 20px;
      margin-left: 0px;
      h4 {
        font-size: 20px;
      }
      .Question-num {
        h3,
        P {
          font-size: 16px;
        }
      }
    }
  }
}
