/** @format */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
@import "../variables/allvariables";

.Header {
  width: 100%;
  // min-width: 100vh;
  background-color: $white-bg;
  // background-color: gold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
  padding: 20px;
  .HeadingHeadr {
    position: relative;
    // background-color: pink;
    h1 {
      font-weight: 500;
      font-size: 21px;
      letter-spacing: 0px;
      color: $primary-color;
      span {
        margin-left: 10px;
        font-size: 16px;
        color: $theme-color;
      }
    }
  }
  .Avatar {
    position: relative;
    // background-color: pink;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    p {
      font: Regular 13px/20px Source Sans Pro;
      letter-spacing: 0px;
      color: $text-color;
      font-weight: normal;
    }
    .profile {
      width: 44px;
      height: 44px;
      margin-left: 16px;
    }
    .dropdown {
      position: absolute;
      // background-color: red;
      bottom: -110%;
      right: 0%;
      padding: 10px;
      align-items: center;
      justify-content: center;
      background-color: $white-bg;
      box-shadow: 0 0 5px #{$border-color};
      z-index: 10;
      .d_field {
        cursor: pointer;
        all: unset;
        color: $text-color;
        // background-color: green;
        white-space: nowrap;
        font-weight: normal;
      }
    }
  }
}
.container {
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 91.5vh;
  // max-height: 500vh;
  // background-color: #eeeeee;
  // background-color: gold;
  // min-width: 140vh;
  justify-content: space-between;
  // flex-wrap: wrap;
  .inner_container {
    width: 100%;
    position: relative;
    // background-color: pink;
  }

  h1 {
    padding-top: 20px;
    padding-left: 90px;
    text-align: left;
    // font: Regular 28px/40px Source Sans Pro;
    letter-spacing: 0px;
    color: $primary-color;
    opacity: 1;
    font-size: 28px/40px;
    font-family: 'Lato', sans-serif;
    font-weight: normal;
  }
  .Wrap {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    // background-color: rgba(0, 0, 0, 0.36);
    // justify-content: space-between;
    .Roomlink {
      width: 40%;
      border-radius: 4px;
      background-color: $white-bg;
      // background-color: gold;
      padding: 30px 30px 40px;
      box-shadow: 0 0 6px #{$border-color};
      h4 {
        // font: Regular 28px/40px Source Sans Pro;
        letter-spacing: 0px;
        color: $primary-color;
        font-size: 30px;
        font-family: 'Lato', sans-serif;
        font-weight: 700;
      }
      h3 {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        font-size: 18px;
        text-align: left;
        margin-top: 10px;
        letter-spacing: 0px;
        color: $primary-color;
      }
      .Link {
        margin-top: 40px;
        display: flex;
        width: 100%;
        // background-color: red;
        // justify-content: space-between;

        input {
          font-family: 'Lato', sans-serif;
          font-weight: 400;
          font-size: 18px;
          outline: none;
          background-color: rgba(0, 0, 0, 0.08);
          padding: 16px;
          color: $label-color;
          // padding-left: 20px;
          width: 70%;
          border-radius: 12px;
          border: 1px solid $border-color;
        }
        .copylink {
          background-color: $second-color;
          padding: 14px 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 12px;
          margin-left: 14px;
          cursor: pointer;
        }
      }
    }
    .history {
      margin: 0 2%;
      width: 40%;
      border-radius: 4px;
      background-color: $white-bg;
      // background-color: gold;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 30px 30px 40px;
      box-shadow: 0 0 6px #{$border-color};
      .history_p1,
      .history_p2,
      .history_p3 {
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        font-size: 18px;
        color: $text-color;
        .history_sp1 {
          // background-color: gold;
          color: $text-color;
          font-family: 'Lato', sans-serif;
          font-weight: 700;
          font-size: 40px;
          margin-right: 14px;
        }
      }
      .history_p2 {
        // margin: 10px 0;
        margin-top: 10px;
        .history_sp1 {
          color: $orange-color;
        }
      }
      .history_p3 {
        .history_sp1 {
          color: $orange-color;
        }
      }
    }
    .CallPermission {
      // position: absolute;
      // right: 50px;
      // top: 120px;
      // align-self: center;
      width: 12%;
      // height: 220px;
      background: $primary-color 0% 0% no-repeat padding-box;
      opacity: 1;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      // padding: 20px;
      // padding: 16px;
      // justify-content: space-between;
      // padding: 10px;
      // align-items: center;
      color: $text-color;
      -webkit-box-shadow: 2px 4px 5px -1px #{$border-color};
      -moz-box-shadow: 2px 4px 5px -1px  #{$border-color};
      box-shadow: 2px 4px 5px -1px  #{$border-color};
      .cp_image,
      .cp_image_red {
        height: 40px;
        width: 40px;
        align-self: center;
        // margin-top: 40px;
      }
      .cp_p1,
      .cp_p1_red {
        margin-top: 16px;
        width: 90%;
        align-self: center;
        text-align: center;
        color: $white-color;
        font-weight: 400;
        font-size: 18px;
        font-family: 'Lato', sans-serif;
      }
      .cp_webcam_parent {
        background-color: $white-bg;
        border-radius: 16px;
        height: 100%;
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .cp_webcam {
          border-radius: 10px;
          height: 100%;
          width: 100%;
          margin: 0;
          padding: 0;
          object-fit: cover;
        }
        .TedstVidecCallbtn {
          border-radius: 20px;
          width: 150px;
          background-color: $second-color;
          color: $white-color;
        }
      }
      .get-permission-btn {
        width: 90%;
        align-self: center;
        margin-top: 16px;
        padding: 12px;
        border-radius: 5px;
        // height: 50px;
        color: $white-color;
        font-weight: bold;
        border: none;
        outline: none;
        transition: 0.2s;
        font-family: 'Lato', sans-serif;
        background-color: $theme-color;
        &:hover {
          opacity: 0.9;
          cursor: pointer;
        }
      }
    }
  }
  .alignn {
    display: flex;
    margin-top: 30px;
    margin-left: 90px;
    .waitinge {
      // border-radius: 5px;
      // height: 284px;
      overflow: auto;
      overflow-x: hidden;
      // width: 500px;
      padding: 20px;
      width: 554px;
      height: 300px;
      background-color: $white-bg;
      // padding: 20px;
      h2 {
        // color: #000000;
        font-family: 'Lato', sans-serif;
        // font-size: 24px;
        font-weight: normal;
        // text-align: left;
        text-align: left;
        font: Regular 24px/25px Source Sans Pro;
        letter-spacing: 0px;
        color: $primary-color;
        opacity: 1;
      }
      .waitpatient {
        width: 500px;

        margin-top: 15px;
        // width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          text-align: left;
          // font: Regular 18px/25px Source Sans Pro;
          letter-spacing: 0px;
          // color: blue;
          // opacity: 0.5;
          width: 150px;
          // background-color: red;
          overflow-wrap: break-word;
          color: $text-color;
          font-family: 'Lato', sans-serif;
          // font-size: 18px;
          font-weight: normal;
          // overflow-y: hidden;
        }
        .time {
          display: flex;
          align-items: center;
          img {
            margin-right: 10px;
          }
          span {
            letter-spacing: 1px;
            width: 50px;
            // padding-left: 13px;
            color: $primary-color;
            font-family: 'Lato', sans-serif;
            font-size: 18px;
            font-weight: normal;
          }
        }
        .btn {
          text-align: center;
          text-decoration: none;
          outline: none;
          border: none;
          padding: 6px;
          width: 100px;
          background-color: $second-color;
          font-size: 14px;
          font-weight: normal;
          font-family: 'Lato', sans-serif;
          color: $white-color;
          border-radius: 5px;
          box-shadow: 0px 3px 6px #{$border-color};
          width: 87px;
          height: 32px;
          &:hover {
            cursor: pointer;
          }
        }
        .btn-details {
          text-align: center;
          text-decoration: none;
          outline: none;
          border: none;
          padding: 6px;
          width: 100px;
          background: $error-color 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 6px #{$border-color};
          // background-color: #474747;
          font-size: 14px;
          font-weight: normal;
          font-family: 'Lato', sans-serif;
          color: $white-color;
          border-radius: 5px;
          width: 87px;
          height: 32px;
          &:hover {
            cursor: pointer;
          }
        }
        .btn-hidedetails {
          text-align: center;
          text-decoration: none;
          outline: none;
          border: none;
          padding: 6px;
          width: 100px;
          background: $white-bg 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 6px #{$border-color};
          border: 1px solid #{$error-color};
          // background-color: #474747;
          font-size: 14px;
          font-weight: normal;
          font-family:'Lato', sans-serif;
          color: $error-color;
          border-radius: 5px;
          width: 87px;
          height: 32px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .DetailsModal {
      margin-left: 20px;
      margin-top: -70px;
      // position: absolute;
      height: 200px auto;
      width: 400px;
      background-color: $white-bg;
      .Close-Btn {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        button {
          width: 25px;
          height: 24px;
          background: $primary-color 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 6px #{$border-color};
          border-radius: 4px;
          opacity: 1;
          margin: 10px;
          color: $white-color;
          outline: none;
          border: none;
          &:hover {
            background-color: $white-bg;
            border: 1px solid $primary-color;
            color: $primary-color;
            cursor: pointer;
          }
        }
      }
      .content {
        margin-bottom: 40px;
        h3 {
          margin-top: -10px;
          padding-left: 10px;
          text-align: left;
          font-size: 18px;
          font-family: 'Lato', sans-serif;
          letter-spacing: 0px;
          font-weight: 700;
          color: $primary-color;
          opacity: 1;
        }
        .info {
          margin-top: 10px;
          display: flex;
          // justify-content:space-between;
          .Keys {
            font-size: 18px;
            font-family: 'Lato', sans-serif;
            letter-spacing: 0px;
            font-weight: 700;
            color: $primary-color;
            padding-left: 20px;
            width: 184px;
          }
          .valuess {
            word-wrap: break-word;
            font-family: 'Lato', sans-serif;
            letter-spacing: 0px;
            width: 200px;
            overflow: break-word;
            // font-weight: 700;
            color: $primary-color;
            // padding-left: 100px;
          }
        }
      }
    }
  }

  .Conta {
    height: 100%;
    width: 100%;
    min-height: 91.5vh;
    padding: 20px;
    // max-height: 100vh;
    // background-color: #eeeeee;

    // background-color: gold;
    // min-width: 140vh;
    // justify-content: space-between;
    .Wrapper {
      // background-color: pink;
      width: 100%;
      display: flex;
      // align-items: flex-start;
      // justify-content: flex-start;
      // padding-right: 70px;
      // flex-wrap: wrap;
      // justify-content: flex-start;
      .MenuBox {
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        // align-items: center;
        // margin-top: 30px;
        // margin-left: -390px;
        //   height: 133px;
        width: 25%;
        background-color: $primary-color;
        // background-color: blue;
        padding: 30px 30px 80px;
        -webkit-box-shadow: 2px 4px 5px -1px #{$border-color};
        -moz-box-shadow: 2px 4px 5px -1px #{$border-color};
        box-shadow: 2px 4px 5px -1px #{$border-color};
        .imgstatus {
          position: relative;
          height: 120px;
          width: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
          img {
            height: 100%;
            width: 100%;
            border-radius: 100px;
          }

          .status {
            //   top: 0px;
            //   left: 0px;
            bottom: 10px;
            right: 5px;
            border: 3px solid #{$border-color};
            position: absolute;
            height: 20px;
            width: 20px;
            border-radius: 100%;
            background-color: $theme-color;
          }
        }
        .Patient-text {
          // margin-left: -60px;
          // margin-top: -20px;
          width: 100%;
          display: flex;
          flex-direction: column;
          // background-color: red;
          // align-items: flex-start;
          // justify-content: flex-start;
          h1 {
            // font-size: 23px;
            font-weight: normal;
            padding: 0;
            color: $white-color;
            margin-top: 20px;
            // font-family: "Source Sans Pro";
            // text-align: left;
            font-size: 18px;
            font-weight: 700;
            font-family: 'Lato', sans-serif;
            letter-spacing: 0px;
            opacity: 1;
          }
          span {
            // padding-left: 90px;
            color: $white-color;
            margin-top: 5px;
            font-size: 16px;
            font-weight: 400;
            font-family: 'Lato', sans-serif;
          }
          .doctorHistory {
            margin-top: 20px;
            // background-color: pink;
            display: flex;
            // align-items: center;
            justify-content: space-between;
            .doctorContent {
              // background-color: gold;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              .doctor_p1 {
                font-size: 18px;
                font-weight: 700;
                font-family: 'Lato', sans-serif;
                color: $white-color;
              }
              .doctor_p2 {
                font-size: 14px;
                font-weight: 400;
                font-family: 'Lato', sans-serif;
                color: $text-color;
                text-align: center;
                flex-grow: 1;
              }
            }
          }
        }
        .BtnCall {
          outline: none;
          border: none;
          padding: 8px;
          border-radius: 5px;
          // height: 50px;
          width: 90px;
          color: $white-color;
          background-color: $primary-color;
          &:hover {
            cursor: pointer;
            background-color: $white-bg;
            border: 1px solid $primary-color;
            font-weight: bold;
            transition: 0.1s;
            color: $primary-color;
            -webkit-box-shadow: 2px 4px 5px -1px #{$border-color};
            -moz-box-shadow: 2px 4px 5px -1px #{$border-color};
            box-shadow: 2px 4px 5px -1px #{$border-color};
          }
        }
      }

      .doctorInformation {
        padding: 30px 30px 80px;
        border-radius: 4px;
        margin: 0% 2%;
        background-color: $white-bg;
        width: 50%;
        -webkit-box-shadow: 2px 4px 5px -1px #{$border-color};
        -moz-box-shadow: 2px 4px 5px -1px #{$border-color};
        box-shadow: 2px 4px 5px -1px #{$border-color};
        .topContent {
          display: flex;
          justify-content: space-between;
          .doctorContent {
            width: 40%;
            .doctorContent_p1 {
              font-size: 18px;
              font-weight: 700;
              font-family: 'Lato', sans-serif;
              color: $primary-color;
            }
            .doctorContent_p2 {
              font-size: 18px;
              font-weight: 400;
              font-family: 'Lato', sans-serif;
              color: $primary-color;
              margin-top: 10px;
            }
          }
        }
        .bottomContent {
          margin-top: 30px;
          .doctorContent {
            width: 80%;
            .doctorContent_p1 {
              font-size: 18px;
              font-weight: 700;
              font-family: 'Lato', sans-serif;
              color: $primary-color;
            }
            .doctorContent_p2 {
              font-size: 18px;
              font-weight: 400;
              font-family: 'Lato', sans-serif;
              color: $primary-color;
              margin-top: 10px;
              word-break: break-all;
            }
          }
        }
      }
      .CallPermission {
        // position: absolute;
        // right: 50px;
        // top: 120px;
        // align-self: center;
        width: 18%;
        // height: 220px;
        background: $primary-color 0% 0% no-repeat padding-box;
        opacity: 1;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        // padding: 20px;
        // padding: 16px;
        // justify-content: space-between;
        // padding: 10px;
        // align-items: center;
        color: #000000;
        -webkit-box-shadow: 2px 4px 5px -1px #{$border-color};
        -moz-box-shadow: 2px 4px 5px -1px #{$border-color};
        box-shadow: 2px 4px 5px -1px #{$border-color};
        .cp_image,
        .cp_image_red {
          height: 40px;
          width: 40px;
          align-self: center;
          // margin-top: 40px;
        }
        .cp_p1,
        .cp_p1_red {
          margin-top: 16px;
          width: 90%;
          align-self: center;
          text-align: center;
          color: $white-color;
          font-weight: 400;
          font-size: 18px;
          font-family:'Lato', sans-serif;
        }
        .cp_webcam_parent {
          background-color: $white-bg;
          border-radius: 16px;
          height: 100%;
          width: 100%;
          padding: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .cp_webcam {
            border-radius: 10px;
            height: 80%;
            width: 100%;
            margin: 0;
            padding: 0;
            object-fit: cover;
          }
          .TedstVidecCallbtn {
            border-radius: 20px;
            width: 150px;
            background-color: $second-color;
            color: $white-color;
          }
        }
        // .cp_webcam {
        //   height: 100%;
        //   width: 100%;
        //   margin: 0;
        //   padding: 0;
        //   object-fit: cover;
        // }
        .get-permission-btn {
          width: 90%;
          align-self: center;
          margin-top: 16px;
          padding: 12px;
          border-radius: 5px;
          // height: 50px;
          color: $white-color;
          font-weight: bold;
          border: none;
          outline: none;
          transition: 0.2s;
          background-color: $theme-color;
          &:hover {
            opacity: 0.9;
            cursor: pointer;
          }
        }
      }
    }
    .QueBox {
      border-radius: 5px;
      margin-top: 30px;
      margin-left: 90px;
      //   height: 203px;
      width: 400px;
      background-color: $white-bg;
      padding: 20px;
      -webkit-box-shadow: 2px 4px 5px -1px #{$border-color};
      -moz-box-shadow: 2px 4px 5px -1px #{$border-color};
      box-shadow: 2px 4px 5px -1px #{$border-color};
      h1 {
        padding: 0px;
        font-size: 24px;
        font-weight: normal;
        color: $primary-color;
        font-family: 'Lato', sans-serif;
      }
      h2 {
        text-align: left;
        // font: Regular 18px Source Sans Pro;
        letter-spacing: 0px;
        color: $primary-color;
        opacity: 1;
        padding-top: 30px;
        font-size: 18px;
        font-weight: normal;
        // color: #000000;
        // font-family: "Source Sans Pro";
      }
      .Time {
        margin-top: 5px;
        width: 80%;
        display: flex;
        justify-content: space-between;
        h3 {
          text-align: left;
          // font: Regular 18px/25px Source Sans Pro;
          letter-spacing: 0px;
          color: $primary-color;
          opacity: 1;
          font-size: 18px;
          font-weight: normal;
          // color: #000000;
          // font-family: "Source Sans Pro";
        }
        h4 {
          font-size: 18px;
          font-weight: normal;
          color: $text-color;
          font-family: 'Lato', sans-serif;
        }
      }
    }
  }
}
.react-responsive-modal-overlay {
  .react-responsive-modal-modal {
    .PatientDetails {
      // width: 800px;
      height: 532px;
      // background: #FFFFFF 0% 0% no-repeat padding-box;
      // box-shadow: 0px 2px 6px #0000000A;
      opacity: 1;
      // margin-top: 30px;
      // margin-left: 90px;
      // margin-bottom: 20px;
      h5 {
        padding: 10px;
        text-align: left;
        font-size: 24px;
        font-family: 'Lato', sans-serif;
        letter-spacing: 0px;
        font-weight: 700;
        color: $primary-color;
        opacity: 1;
      }
      .Patientdata {
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 600px;
        .input {
          input {
            margin-top: 5px;
            width: 265px;
            height: 42px;
            outline: none;
            border: none;
            padding: 10px;
            background: $page-bg 0% 0% no-repeat padding-box;
            border-radius: 4px;
            opacity: 0.9;
            &:hover {
              cursor: not-allowed;
            }
          }
        }
      }
      .crossBtn {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

@media only screen and (max-width: 1680px) {
  .Header {
    .HeadingHeadr {
      h1 {
        font-size: 16px;
        span {
          font-size: 12px;
        }
      }
    }
    .Avatar {
      .dropdown {
        .d_field {
          font-size: 14px;
        }
      }
    }
  }
  .container {
    // background-color: gold;
    .inner_container {
      width: 100%;
      // background-color: pink;
      h1 {
      }
      .Roomlink {
        width: 80%;
        // background-color: gold;
      }
      .alignn {
        width: 80%;
        // background-color: gold;
        .waitinge {
          width: 100%;
          // background-color: blue;
          .waitpatient {
            width: 100%;
            // background-color: pink;
            button.btn-details {
              display: none;
            }
          }
        }
      }
      .Wrap {
        // background-color: rgba(0, 0, 0, 0.36);
        // justify-content: space-between;
        .Roomlink {
          width: 38%;
          padding: 30px 30px 40px;
          h4 {
            font-size: 30px;
          }
          h3 {
            font-size: 18px;
          }
          .Link {
            margin-top: 40px;
            width: 100%;

            input {
              font-size: 18px;
              width: 75%;
            }
            .copylink {
              padding: 14px 24px;
              margin-left: 14px;
            }
          }
        }
        .history {
          margin: 0 2%;
          width: 38%;
          padding: 30px 30px 40px;
          .history_p1,
          .history_p2,
          .history_p3 {
            font-size: 18px;
            .history_sp1 {
              font-size: 40px;
            }
          }
          .history_p2 {
            margin: 10px 0;
          }
          .history_p3 {
            .history_sp1 {
              color: $orange-color;
            }
          }
        }
        .CallPermission {
          width: 20%;
          .cp_image,
          .cp_image_red {
            height: 40px;
            width: 40px;
          }
          .cp_p1,
          .cp_p1_red {
            margin-top: 16px;
          }

          .cp_webcam {
          }
          .get-permission-btn {
            width: 90%;
            font-size: 14px;
            transition: 0s;
          }
        }
      }
    }
    .Conta {
      .Wrapper {
        .MenuBox {
          width: 25%;
          padding-bottom: 50px;
          .Patient-text {
            h1 {
              font-size: 18px;
            }
            span {
              font-size: 16px;
            }
            .doctorHistory {
              margin-top: 20px;
              .doctorContent {
                .doctor_p1 {
                  font-size: 18px;
                }
                .doctor_p2 {
                  font-size: 14px;
                }
              }
            }
          }
        }
        .doctorInformation {
          width: 48%;
          padding-bottom: 50px;
          .topContent {
            display: flex;
            justify-content: space-between;
            .doctorContent {
              // width: 40%;
              .doctorContent_p1 {
                font-size: 18px;
              }
              .doctorContent_p2 {
                font-size: 18px;
              }
            }
          }
          .bottomContent {
            .doctorContent {
              width: 100%;
              .doctorContent_p1 {
                font-size: 18px;
              }
              .doctorContent_p2 {
                font-size: 18px;
              }
            }
          }
        }
        .CallPermission {
          width: 20%;
          .cp_image,
          .cp_image_red {
          }
          .cp_p1,
          .cp_p1_red {
            font-size: 18px;
          }
          .cp_webcam {
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .Header {
    .HeadingHeadr {
      h1 {
        font-size: 16px;
        span {
          font-size: 12px;
        }
      }
    }
    .Avatar {
      .dropdown {
        .d_field {
          font-size: 14px;
        }
      }
    }
  }
  .container {
    // background-color: gold;
    .inner_container {
      width: 100%;
      // background-color: pink;
      h1 {
      }
      .Roomlink {
        width: 80%;
      }
      .alignn {
        width: 80%;
        // background-color: gold;
        .waitinge {
          width: 100%;
          // background-color: blue;
          .waitpatient {
            width: 100%;
            // background-color: pink;
            button.btn-details {
              display: none;
            }
          }
        }
      }
      .Wrap {
        // background-color: rgba(0, 0, 0, 0.36);
        // justify-content: space-between;
        .Roomlink {
          width: 40%;
          padding: 30px 30px 40px;
          h4 {
            font-size: 26px;
          }
          h3 {
            font-size: 16px;
          }
          .Link {
            margin-top: 40px;
            width: 100%;

            input {
              font-size: 16px;
              width: 70%;
            }
            .copylink {
              padding: 14px 24px;
              margin-left: 14px;
            }
          }
        }
        .history {
          margin: 0 2%;
          width: 40%;
          padding: 30px 30px 40px;
          .history_p1,
          .history_p2,
          .history_p3 {
            font-size: 16px;
            .history_sp1 {
              font-size: 36px;
            }
          }
          .history_p2 {
            margin: 10px 0;
          }
          .history_p3 {
            .history_sp1 {
              color: $orange-color;
            }
          }
        }
        .CallPermission {
          position: absolute;
          right: 0px;
          top: 0px;
          height: 220px;
          width: 220px;
          .cp_image,
          .cp_image_red {
            height: 36px;
            width: 36px;
            margin-top: 20px;
          }
          .cp_p1,
          .cp_p1_red {
            font-size: 14px;
            // margin-bottom: 20px;
          }
          .cp_webcam {
          }

          .get-permission-btn {
            // margin-bottom: 20px;
          }
        }
      }
    }
    .Conta {
      // background-color: gold;
      position: relative;
      .Wrapper {
        .MenuBox {
          width: 30%;
          padding-bottom: 50px;
          .Patient-text {
            h1 {
              font-size: 16px;
            }
            span {
              font-size: 16px;
            }
            .doctorHistory {
              margin-top: 20px;
              .doctorContent {
                .doctor_p1 {
                  font-size: 16px;
                }
                .doctor_p2 {
                  font-size: 14px;
                }
              }
            }
          }
        }
        .doctorInformation {
          width: 50%;
          padding-bottom: 50px;
          .topContent {
            display: flex;
            justify-content: space-between;
            .doctorContent {
              // width: 40%;
              .doctorContent_p1 {
                font-size: 16px;
              }
              .doctorContent_p2 {
                font-size: 16px;
              }
            }
          }
          .bottomContent {
            .doctorContent {
              // width: 80%;
              .doctorContent_p1 {
                font-size: 16px;
              }
              .doctorContent_p2 {
                font-size: 16px;
              }
            }
          }
        }
        .CallPermission {
          position: absolute;
          right: 0px;
          top: 0px;
          height: 220px;
          width: 220px;
          .cp_image,
          .cp_image_red {
            height: 36px;
            width: 36px;
            margin-top: 20px;
          }
          .cp_p1,
          .cp_p1_red {
            font-size: 14px;
            // margin-bottom: 20px;
          }
          .cp_webcam {
          }

          .get-permission-btn {
            // margin-bottom: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1080px) {
  .container {
    // background-color: gold;
    .inner_container {
      width: 100%;
      // background-color: pink;
      h1 {
      }
      .Roomlink {
        width: 80%;
      }
      .alignn {
        width: 80%;
        // background-color: gold;
        .waitinge {
          width: 100%;
          // background-color: blue;
          .waitpatient {
            width: 100%;
            // background-color: pink;
            button.btn-details {
              display: none;
            }
          }
        }
      }
      .Wrap {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        // background-color: rgba(0, 0, 0, 0.36);
        // justify-content: space-between;
        .Roomlink {
          width: 45%;
          padding: 30px 30px 40px;
          h4 {
            font-size: 26px;
          }
          h3 {
            font-size: 16px;
          }
          .Link {
            margin-top: 40px;
            width: 100%;

            input {
              font-size: 16px;
              width: 70%;
            }
            .copylink {
              padding: 14px 24px;
              margin-left: 14px;
            }
          }
        }
        .history {
          margin: 0 2%;
          width: 45%;
          padding: 30px 30px 40px;
          .history_p1,
          .history_p2,
          .history_p3 {
            font-size: 16px;
            .history_sp1 {
              font-size: 36px;
            }
          }
          .history_p2 {
            margin: 10px 0;
          }
          .history_p3 {
            .history_sp1 {
              color: $orange-color;
            }
          }
        }
        .CallPermission {
          position: absolute;
          right: 0px;
          top: 0px;
          height: 100px;
          width: 100px;
          .cp_image,
          .cp_image_red {
            height: 16px;
            width: 16px;
            margin-top: 0px;
          }
          .cp_p1,
          .cp_p1_red {
            font-size: 10px;
            margin-top: 8px;
            margin-bottom: 0px;
            width: 95%;
          }
          .cp_webcam {
          }

          .get-permission-btn {
            font-size: 10px;
            margin-top: 10px;
            padding: 6px;
            font-weight: 600;
          }
        }
      }
    }
    .Conta {
      // background-color: gold;
      position: relative;
      .Wrapper {
        .MenuBox {
          width: 35%;
          padding-bottom: 50px;
          .Patient-text {
            h1 {
              font-size: 16px;
            }
            span {
              font-size: 16px;
            }
            .doctorHistory {
              margin-top: 20px;
              .doctorContent {
                .doctor_p1 {
                  font-size: 16px;
                }
                .doctor_p2 {
                  font-size: 14px;
                }
              }
            }
          }
        }
        .doctorInformation {
          width: 55%;
          padding-bottom: 50px;
          .topContent {
            display: flex;
            justify-content: space-between;
            .doctorContent {
              // width: 40%;
              .doctorContent_p1 {
                font-size: 16px;
              }
              .doctorContent_p2 {
                font-size: 16px;
              }
            }
          }
          .bottomContent {
            .doctorContent {
              // width: 80%;
              .doctorContent_p1 {
                font-size: 16px;
              }
              .doctorContent_p2 {
                font-size: 16px;
              }
            }
          }
        }
        .CallPermission {
          position: absolute;
          right: 0px;
          top: 0px;
          height: 100px;
          width: 100px;
          .cp_image,
          .cp_image_red {
            height: 16px;
            width: 16px;
            margin-top: 0px;
          }
          .cp_p1,
          .cp_p1_red {
            font-size: 10px;
            margin-top: 8px;
            margin-bottom: 0px;
            width: 95%;
          }
          .cp_webcam {
          }

          .get-permission-btn {
            font-size: 10px;
            margin-top: 10px;
            padding: 6px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .container {
    // background-color: gold;
    .inner_container {
      width: 100%;
      // background-color: pink;
      h1 {
      }
      .Roomlink {
        width: 80%;
      }
      .alignn {
        width: 80%;
        // background-color: gold;
        .waitinge {
          width: 100%;
          // background-color: blue;
          .waitpatient {
            width: 100%;
            // background-color: pink;
            button.btn-details {
              display: none;
            }
          }
        }
      }
      .Wrap {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        // background-color: rgba(0, 0, 0, 0.36);
        // justify-content: space-between;
        .Roomlink {
          width: 45%;
          padding: 30px 30px 40px;
          h4 {
            font-size: 26px;
          }
          h3 {
            font-size: 16px;
          }
          .Link {
            margin-top: 40px;
            width: 100%;

            input {
              font-size: 16px;
              width: 70%;
            }
            .copylink {
              padding: 14px 24px;
              margin-left: 14px;
            }
          }
        }
        .history {
          margin: 0 2%;
          width: 45%;
          padding: 30px 30px 40px;
          .history_p1,
          .history_p2,
          .history_p3 {
            font-size: 16px;
            .history_sp1 {
              font-size: 36px;
            }
          }
          .history_p2 {
            margin: 10px 0;
          }
          .history_p3 {
            .history_sp1 {
              color: $orange-color;
            }
          }
        }
        .CallPermission {
          position: absolute;
          right: 0px;
          top: 0px;
          height: 150px;
          width: 150px;
          .cp_image,
          .cp_image_red {
            height: 16px;
            width: 16px;
            margin-top: 0px;
          }
          .cp_p1,
          .cp_p1_red {
            font-size: 10px;
            margin-top: 8px;
            margin-bottom: 0px;
            width: 95%;
          }
          .cp_webcam_parent {
            background-color: $white-bg;
            border-radius: 16px;
            height: 100%;
            width: 100%;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .cp_webcam {
              border-radius: 10px;
              height: 80%;
              width: 100%;
              margin: 0;
              padding: 0;
              object-fit: cover;
            }
            .TedstVidecCallbtn {
              width: 90%;
              border-radius: 20px;
              // width: 150px;
              background-color: $second-color;
              color: $white-color;
              height: 30px;
              margin-top: 5px;
              // .MuiButton-label {
              //   // font-size: 6px;
              //   // height: 20px;
              // }
            }
          }
          // .cp_webcam {
          // }

          .get-permission-btn {
            font-size: 10px;
            margin-top: 10px;
            padding: 6px;
            font-weight: 600;
          }
        }
      }
    }
    .Conta {
      // background-color: gold;
      position: relative;
      .Wrapper {
        .MenuBox {
          width: 47%;
          padding-bottom: 50px;
          .Patient-text {
            h1 {
              font-size: 16px;
            }
            span {
              font-size: 16px;
            }
            .doctorHistory {
              margin-top: 20px;
              .doctorContent {
                .doctor_p1 {
                  font-size: 16px;
                }
                .doctor_p2 {
                  font-size: 14px;
                }
              }
            }
          }
        }
        .doctorInformation {
          width: 47%;
          padding-bottom: 50px;
          .topContent {
            display: flex;
            justify-content: space-between;
            .doctorContent {
              // width: 40%;
              .doctorContent_p1 {
                font-size: 16px;
              }
              .doctorContent_p2 {
                font-size: 16px;
              }
            }
          }
          .bottomContent {
            .doctorContent {
              // width: 100%;
              .doctorContent_p1 {
                font-size: 16px;
              }
              .doctorContent_p2 {
                font-size: 16px;
              }
            }
          }
        }
        .CallPermission {
          position: absolute;
          right: 0px;
          top: -20px;
          height: 100px;
          width: 100px;
          .cp_image,
          .cp_image_red {
            height: 16px;
            width: 16px;
            margin-top: 0px;
          }
          .cp_p1,
          .cp_p1_red {
            font-size: 10px;
            margin-top: 8px;
            margin-bottom: 0px;
            width: 95%;
          }
          .cp_webcam {
          }

          .get-permission-btn {
            font-size: 10px;
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 6px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .container {
    // background-color: gold;
    .inner_container {
      width: 100%;
      // background-color: pink;
      h1 {
      }
      .Roomlink {
        width: 80%;
      }
      .alignn {
        width: 80%;
        // background-color: gold;
        .waitinge {
          width: 100%;
          // background-color: blue;
          .waitpatient {
            width: 100%;
            // background-color: pink;
            button.btn-details {
              display: none;
            }
          }
        }
      }
      .Wrap {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        // background-color: rgba(0, 0, 0, 0.36);
        // justify-content: space-between;
        .Roomlink {
          width: 45%;
          padding: 30px 30px 40px;
          h4 {
            font-size: 26px;
          }
          h3 {
            font-size: 16px;
          }
          .Link {
            margin-top: 40px;
            width: 100%;

            input {
              font-size: 16px;
              width: 70%;
            }
            .copylink {
              padding: 14px 24px;
              margin-left: 14px;
            }
          }
        }
        .history {
          margin: 0 2%;
          width: 45%;
          padding: 30px 30px 40px;
          .history_p1,
          .history_p2,
          .history_p3 {
            font-size: 16px;
            .history_sp1 {
              font-size: 36px;
            }
          }
          .history_p2 {
            margin: 10px 0;
          }
          .history_p3 {
            .history_sp1 {
              color: $orange-color;
            }
          }
        }
        .CallPermission {
          position: absolute;
          right: 0px;
          top: 0px;
          height: 150px;
          width: 150px;
          .cp_image,
          .cp_image_red {
            height: 16px;
            width: 16px;
            margin-top: 0px;
          }
          .cp_p1,
          .cp_p1_red {
            font-size: 10px;
            margin-top: 8px;
            margin-bottom: 0px;
            width: 95%;
          }
          .cp_webcam_parent {
            background-color: $white-bg;
            border-radius: 16px;
            height: 100%;
            width: 100%;
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            .cp_webcam {
              border-radius: 10px;
              height: 80%;
              width: 100%;
              margin: 0;
              padding: 0;
              object-fit: cover;
            }
            .TedstVidecCallbtn {
              width: 90%;
              border-radius: 20px;
              // width: 150px;
              background-color: $second-color;
              color: $white-color;
              height: 30px;
              margin-top: 5px;
              // .MuiButton-label {
              //   // font-size: 6px;
              //   // height: 20px;
              // }
            }
          }
          // .cp_webcam {
          // }

          .get-permission-btn {
            font-size: 10px;
            margin-top: 10px;
            padding: 6px;
            font-weight: 600;
          }
        }
      }
    }
    .Conta {
      // background-color: gold;
      position: relative;
      .Wrapper {
        .MenuBox {
          width: 47%;
          padding-bottom: 50px;
          .Patient-text {
            h1 {
              font-size: 16px;
            }
            span {
              font-size: 16px;
            }
            .doctorHistory {
              margin-top: 20px;
              .doctorContent {
                .doctor_p1 {
                  font-size: 16px;
                }
                .doctor_p2 {
                  font-size: 14px;
                }
              }
            }
          }
        }
        .doctorInformation {
          width: 47%;
          padding-bottom: 50px;
          .topContent {
            display: flex;
            justify-content: space-between;
            .doctorContent {
              // width: 40%;
              .doctorContent_p1 {
                font-size: 16px;
              }
              .doctorContent_p2 {
                font-size: 16px;
              }
            }
          }
          .bottomContent {
            .doctorContent {
              // width: 100%;
              .doctorContent_p1 {
                font-size: 16px;
              }
              .doctorContent_p2 {
                font-size: 16px;
              }
            }
          }
        }
        .CallPermission {
          position: absolute;
          right: 0px;
          top: -20px;
          height: 100px;
          width: 100px;
          .cp_image,
          .cp_image_red {
            height: 16px;
            width: 16px;
            margin-top: 0px;
          }
          .cp_p1,
          .cp_p1_red {
            font-size: 10px;
            margin-top: 8px;
            margin-bottom: 0px;
            width: 95%;
          }
          .cp_webcam {
          }

          .get-permission-btn {
            font-size: 10px;
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 6px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .container {
    // background-color: gold;
    .inner_container {
      width: 100%;
      // background-color: pink;
      h1 {
      }
      .Roomlink {
        width: 80%;
      }
      .alignn {
        width: 80%;
        // background-color: gold;
        .waitinge {
          width: 100%;
          // background-color: blue;
          .waitpatient {
            width: 100%;
            // background-color: pink;
            button.btn-details {
              display: none;
            }
          }
        }
      }
      .Wrap {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        // background-color: rgba(0, 0, 0, 0.36);
        // justify-content: space-between;
        .Roomlink {
          width: 100%;
          padding: 30px 30px 40px;
          h4 {
            font-size: 26px;
          }
          h3 {
            font-size: 16px;
          }
          .Link {
            margin-top: 40px;
            width: 100%;

            input {
              font-size: 16px;
              width: 70%;
            }
            .copylink {
              padding: 14px 24px;
              margin-left: 14px;
            }
          }
        }
        .history {
          margin: 0 0%;
          margin-top: 30px;
          width: 100%;
          padding: 30px 30px 40px;
          .history_p1,
          .history_p2,
          .history_p3 {
            font-size: 16px;
            .history_sp1 {
              font-size: 36px;
            }
          }
          .history_p2 {
            margin: 10px 0;
          }
          .history_p3 {
            .history_sp1 {
              color: $orange-color;
            }
          }
        }
        .CallPermission {
          position: absolute;
          right: -20px;
          top: -20px;
          height: 150px;
          width: 150px;
          .cp_image,
          .cp_image_red {
            height: 16px;
            width: 16px;
            margin-top: 0px;
          }
          .cp_p1,
          .cp_p1_red {
            font-size: 10px;
            margin-top: 8px;
            margin-bottom: 0px;
            width: 95%;
          }
          .cp_webcam {
          }

          .get-permission-btn {
            font-size: 10px;
            margin-top: 10px;
            padding: 6px;
            font-weight: 600;
          }
        }
      }
    }
    .Conta {
      // background-color: gold;
      position: relative;
      .Wrapper {
        flex-wrap: wrap;
        .MenuBox {
          width: 100%;
          padding-bottom: 30px;
          .Patient-text {
            h1 {
              font-size: 16px;
            }
            span {
              font-size: 16px;
            }
            .doctorHistory {
              margin-top: 20px;
              .doctorContent {
                .doctor_p1 {
                  font-size: 16px;
                }
                .doctor_p2 {
                  font-size: 14px;
                }
              }
            }
          }
        }
        .doctorInformation {
          width: 100%;
          padding-bottom: 30px;
          margin: 0;
          margin-top: 30px;
          .topContent {
            display: flex;
            justify-content: space-between;
            .doctorContent {
              // width: 40%;
              .doctorContent_p1 {
                font-size: 16px;
              }
              .doctorContent_p2 {
                font-size: 16px;
              }
            }
          }
          .bottomContent {
            .doctorContent {
              // width: 80%;
              .doctorContent_p1 {
                font-size: 16px;
              }
              .doctorContent_p2 {
                font-size: 16px;
              }
            }
          }
        }
        .CallPermission {
          position: absolute;
          right: 0px;
          top: 0px;
          height: 100px;
          width: 100px;
          .cp_image,
          .cp_image_red {
            height: 16px;
            width: 16px;
            margin-top: 0px;
          }
          .cp_p1,
          .cp_p1_red {
            font-size: 10px;
            margin-top: 8px;
            margin-bottom: 0px;
            width: 95%;
          }
          .cp_webcam {
          }

          .get-permission-btn {
            font-size: 10px;
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 6px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .container {
    // background-color: gold;
    .inner_container {
      width: 100%;
      // background-color: pink;
      h1 {
      }
      .Roomlink {
        width: 80%;
      }
      .alignn {
        width: 80%;
        // background-color: gold;
        .waitinge {
          width: 100%;
          // background-color: blue;
          .waitpatient {
            width: 100%;
            // background-color: pink;
            button.btn-details {
              display: none;
            }
          }
        }
      }
      .Wrap {
        position: relative;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        // background-color: gold;
        // background-color: rgba(0, 0, 0, 0.36);
        // justify-content: space-between;
        .Roomlink {
          width: 100%;
          padding: 20px;
          margin-top: 18px;
          h4 {
            font-size: 20px;
          }
          h3 {
            font-size: 14px;
          }
          .Link {
            margin-top: 40px;
            width: 100%;

            input {
              font-size: 14px;
              width: 70%;
            }
            .copylink {
              padding: 14px 24px;
              margin-left: 14px;
            }
          }
        }
        .history {
          margin: 0 0%;
          margin-top: 30px;
          width: 100%;
          padding: 20px;
          .history_p1,
          .history_p2,
          .history_p3 {
            font-size: 14px;
            .history_sp1 {
              font-size: 18px;
              margin-right: 3px;
            }
          }
          .history_p2 {
            margin: 10px 0;
          }
          .history_p3 {
            .history_sp1 {
              color: $orange-color;
            }
          }
        }
        .CallPermission {
          position: absolute;
          right: -20px;
          top: -20px;
          height: 140px;
          width: 140px;
          .cp_image,
          .cp_image_red {
            height: 16px;
            width: 16px;
            margin-top: 0px;
          }
          .cp_p1,
          .cp_p1_red {
            font-size: 10px;
            margin-top: 8px;
            margin-bottom: 0px;
            width: 95%;
          }
          .cp_webcam {
          }

          .get-permission-btn {
            font-size: 10px;
            margin-top: 10px;
            padding: 6px;
            font-weight: 600;
          }
        }
      }
    }
    .Conta {
      // background-color: gold;
      position: relative;
      .Wrapper {
        flex-wrap: wrap;
        .MenuBox {
          width: 100%;
          padding-bottom: 30px;
          .Patient-text {
            h1 {
              font-size: 14px;
            }
            span {
              font-size: 14px;
            }
            .doctorHistory {
              margin-top: 20px;
              .doctorContent {
                .doctor_p1 {
                  font-size: 14px;
                }
                .doctor_p2 {
                  font-size: 14px;
                }
              }
            }
          }
        }
        .doctorInformation {
          width: 100%;
          padding-bottom: 30px;
          margin: 0;
          margin-top: 30px;
          .topContent {
            display: flex;
            justify-content: space-between;
            .doctorContent {
              // width: 40%;
              .doctorContent_p1 {
                font-size: 14px;
              }
              .doctorContent_p2 {
                font-size: 14px;
              }
            }
          }
          .bottomContent {
            .doctorContent {
              // width: 80%;
              .doctorContent_p1 {
                font-size: 14px;
              }
              .doctorContent_p2 {
                font-size: 14px;
              }
            }
          }
        }
        .CallPermission {
          position: absolute;
          right: 20px;
          top: 20px;
          height: 100px;
          width: 100px;
          .cp_image,
          .cp_image_red {
            height: 16px;
            width: 16px;
            margin-top: 0px;
          }
          .cp_p1,
          .cp_p1_red {
            font-size: 10px;
            margin-top: 8px;
            margin-bottom: 0px;
            width: 95%;
          }

          // .cp_webcam {
          // }

          .get-permission-btn {
            font-size: 10px;
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 6px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .container {
    // background-color: gold;
    .inner_container {
      width: 100%;
      // background-color: pink;
      h1 {
      }
      .Roomlink {
        width: 80%;
      }
      .alignn {
        width: 80%;
        // background-color: gold;
        .waitinge {
          width: 100%;
          // background-color: blue;
          .waitpatient {
            width: 100%;
            // background-color: pink;
            button.btn-details {
              display: none;
            }
          }
        }
      }
      .Wrap {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        // background-color: rgba(0, 0, 0, 0.36);
        // justify-content: space-between;
        .Roomlink {
          width: 100%;
          padding: 20px;
          margin-top: 0px;
          h4 {
            font-size: 18px;
            width: 70%;
          }
          h3 {
            font-size: 14px;
          }
          .Link {
            margin-top: 20px;
            width: 100%;

            input {
              font-size: 14px;
              width: 70%;
            }
            .copylink {
              padding: 8px 10px;
              margin-left: 14px;
            }
          }
        }
        .history {
          margin: 0 0%;
          margin-top: 30px;
          width: 100%;
          padding: 20px;
          .history_p1,
          .history_p2,
          .history_p3 {
            font-size: 14px;
            .history_sp1 {
              font-size: 18px;
              margin-right: 3px;
            }
          }
          .history_p2 {
            margin: 10px 0;
          }
          .history_p3 {
            .history_sp1 {
              color: $orange-color;
            }
          }
        }
        .CallPermission {
          position: absolute;
          right: -20px;
          top: -20px;
          height: 140px;
          width: 140px;
          .cp_image,
          .cp_image_red {
            height: 16px;
            width: 16px;
            margin-top: 0px;
          }
          .cp_p1,
          .cp_p1_red {
            font-size: 10px;
            margin-top: 8px;
            margin-bottom: 0px;
            width: 95%;
          }
          .cp_webcam {
          }

          .get-permission-btn {
            font-size: 10px;
            margin-top: 10px;
            padding: 6px;
            font-weight: 600;
          }
        }
      }
    }
    .Conta {
      // background-color: gold;
      position: relative;
      .Wrapper {
        flex-wrap: wrap;
        .MenuBox {
          width: 100%;
          padding: 22px;
          .Patient-text {
            h1 {
              font-size: 14px;
            }
            span {
              font-size: 14px;
            }
            .doctorHistory {
              margin-top: 20px;
              .doctorContent {
                .doctor_p1 {
                  font-size: 14px;
                }
                .doctor_p2 {
                  font-size: 14px;
                }
              }
            }
          }
        }
        .doctorInformation {
          width: 100%;
          padding: 22px;
          margin: 0;
          margin-top: 30px;
          .topContent {
            display: flex;
            justify-content: center;
            flex-direction: column;
            .doctorContent {
              width: 100%;
              .doctorContent_p1 {
                font-size: 14px;
              }
              .doctorContent_p2 {
                font-size: 14px;
              }
              &:nth-of-type(2) {
                // background-color: gold;
                margin-top: 20px;
              }
            }
          }
          .bottomContent {
            margin-top: 20px;
            .doctorContent {
              // width: 100%;
              .doctorContent_p1 {
                font-size: 14px;
              }
              .doctorContent_p2 {
                font-size: 14px;
              }
            }
          }
        }
        .CallPermission {
          position: absolute;
          right: 20px;
          top: 20px;
          height: 100px;
          width: 100px;
          .cp_image,
          .cp_image_red {
            height: 16px;
            width: 16px;
            margin-top: 0px;
          }
          .cp_p1,
          .cp_p1_red {
            font-size: 10px;
            margin-top: 8px;
            margin-bottom: 0px;
            width: 95%;
          }
          .cp_webcam {
          }

          .get-permission-btn {
            font-size: 10px;
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 6px;
            font-weight: 600;
          }
        }
      }
    }
  }
}
