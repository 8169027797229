@import "../variables/allvariables";
.suc-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // height: 260px;
  // padding: 20px;
  width: 100%;
  // background-color: rgb(88, 214, 88);
  // border: 1px solid rgb(88, 214, 88);
  .suc-imgContainer {
    margin-top: 60px;
    height: 140px;
    width: 140px;
    border-radius: 50%;
    overflow: hidden;
    .suc-img {
      width: 100%;
      height: 100%;
    }
  }

  .suc-h1 {
    margin-top: 4vw;
    font-size: 28px;
    font-weight: 700;
    color: $primary-color;
  }
  .suc-p1 {
    margin-top: 1vw;
    font-weight: 400;
    font-size: 20px;
    // color: rgb(88, 214, 88);
  }
  .suc-btn {
    // background-color: rgb(88, 214, 88);
    background: $second-color;
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 0.8em 2em;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    color: $white-color;
    margin-top: 5vw;
    margin-bottom: 4vw;
    box-shadow: 0px 2px 5px #{$border-color};
    // &:hover {
    //   color: white;
    //   // border: none;
    // }
  }
}
.react-responsive-modal-overlay {
  .react-responsive-modal-modal {
    border-radius: 5px;
    // background-color: green;

    .Modal {
      // height: 300px;
      // border-radius: 10px;
      width: 700px;
      // display: flex;
      flex-direction: column;
      align-items: center;
      // background-color: gold;
      p {
        // margin-left: -120px ;
        padding-top: 20px;
      }
      form {
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        // background-color: grey;
        fieldset {
          border: 1px solid #{$border-color};
          border-color: $border-color;
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          border-color: #474747;
        }
        .MuiFormLabel-root.Mui-focused {
          color: #474747;
        }
        input {
          // outline: none;
          border: none;
          padding: 15px;
          width: 550px;
          // background-color: blue;
        }
        button {
          font-size: 13px;
          border-radius: 5px;
          padding: 12px;
          margin-top: 20px;
          width: 130px;
          background-color: #474747;
          color: white;
          outline: none;
        }
        span {
          // width: 100%;
          font-size: 10px;
          text-align: center;
          padding-top: 10px;
          color: red;
          // background-color: gold;
          // margin-left: -100px;
        }
      }
      h5 {
        font-size: 10px;
        padding-top: 30px;
        text-align: center;
        color: #474747;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .react-responsive-modal-overlay {
    .react-responsive-modal-modal {
      width: 100%;
      .Modal {
        width: 100%;
        form {
          width: 100%;
          .MuiInputBase-root {
            width: 100%;
            // background-color: pink;
          }
          input {
            width: 100%;
          }
        }
      }
      .MuiFormControl-root.MuiTextField-root {
        width: 100%;
        // background-color: red;
      }
    }
  }
}

.Modal.staric-remove .MuiInputLabel-asterisk {
  display: none;
}
