@import "./css/fontawesome/css/all.min.css";
@import "./css/icon.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  /* margin: 0;
  padding: 0; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* box-sizing: border-box; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#table-row:hover {
  background-color: #f8f6f6;
}
