@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
@import "../variables/allvariables";

.tda_modal {
  // background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Lato', sans-serif;
  .tda_modal_content {
    background-color: $white-bg;
    border: none;
    outline: none;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    // align-items: center;
    width: 60%;
    // justify-content: center;
    .tda_sec1 {
      padding: 60px 20px 10px 10px;
      position: relative;
      //   display: flex;
      //   align-items: center;
      //   flex-direction: column;
      background-color: $primary-color;
      .tda_sec1_p1 {
        font-size: 26px;
        font-weight: 700;
        color: $white-color;
        padding-left: 40px;
      }
      .tda_sec1_p2 {
        margin-top: 10px;
        font-size: 18px;
        font-weight: 400;
        color: $text-color;
        padding-left: 40px;
      }
    }
    // .tda_form {
    //   display: flex;
    //   //   align-items: center;
    //   //   justify-content: center;¸
    //   //   background-color: gold;
    // }
    .tda_form {
      .IntrnationalPhoneInput {
        width: 56%;
        margin-top: 0.5em;
      }
      // background-color: gold;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      width: 100%;
      justify-content: space-evenly;
      align-items: center;
      justify-content: center;
      padding: 20px;
      .MuiTextField-root {        
        width: 56%;
        margin-top: 0.5em;
        // .MuiOutlinedInput-input {
        // }
        .MuiOutlinedInput-notchedOutline {
          .PrivateNotchedOutline-legendLabelled-228 {
            span {
              color: $theme-color;
            }
          }
        }
        .MuiFormLabel-root {
          color: $label-color;
          font-size: 16px;
          font-family: 'Lato', sans-serif;
          font-weight: 400;
          &.Mui-focused {
            color: $link-color;
            font-weight: 700;
          }
          &.MuiInputLabel-shrink {
            // color: #00efae;
            font-weight: 700;
          }
          &.Mui-error {
            color: $error-color;
            font-weight: 700;
          }
        }
        // .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        //   border-color: black !important;
        //   border-width: 1px;
        // }
        .MuiInputBase-root {
          &.MuiFilledInput-underline {
            background-color: $label-color;
            border: 1px solid $border-color;
            border-radius: 10px;
            // width: 100%;
            &:hover {
              // border-bottom: none;
            }
            &::before {
              border-bottom: none;
            }
            &::after {
              border-bottom: none;
            }
            &.Mui-error {
              border-color: $error-color;
            }
            .MuiFilledInput-input {
              // width: 30%;
            }
          }
          &.Mui-focused {
            // border: 1px solid red;
          }
          &.MuiInputBase-formControl {
            // border-color: aqua;

            // border: 1px solid aqua;
          }
          &.MuiOutlinedInput-root {
            // border-color: green;
            // border: 1px solid green;
            &.Mui-focused .MuiOutlinedInput-notchedOutline {
              border-color: $primary-color;
              border-width: 1px;
            }
          }
          .MuiOutlinedInput-input {
            // border:1px solid pink
            .MuiOutlinedInput-notchedOutline {
              // border:1px solid pink
            }
          }
        }
      }

      .tda_span1 {
        width: 54%;
        margin-top: 5px;
        color: $error-color;
        // background-color: "gold";
        font-size: 14px;
        font-weight: 400;
        font-family: 'Lato', sans-serif;
      }
      .tda_span {
        margin-top: 10px;
        color: $error-color;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Lato', sans-serif;
      }
      .tda_btn1 {
        .react-tel-input .form-control {
          margin-top: -7px !important;
          background-color: $theme-color !important ;
        }
        // all: unset;
        text-decoration: none;
        cursor: pointer;
        font-size: 16px;
        color: $white-color;
        background-color: $second-color;
        border-color: $second-color;
        border: 2px solid #{$second-color};
        padding: 0.6em 4em;
        border-radius: 5px;
        margin-top: 4em;
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        box-shadow: 0px 0px 8px #{$border-color};
        // &:hover {
        //   color: #1489FF;
        //   background-color: #ffffff;
        //   border: 2px solid #1489FF;
        // }
      }
      .tda_btn1_disable {
        // all: unset;
        text-decoration: none;
        // cursor: pointer;
        font-size: 16px;
        color: $white-color;
        background-color: $disable-bg;
        border: 2px solid $disable-bg;
        padding: 0.6em 4em;
        border-radius: 5px;
        margin-top: 4em;
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        box-shadow: 0px 0px 8px #{$border-color};
      }
      .tda_form_p1 {
        margin-top: 4em;
        font-size: 18px;
        font-weight: 400;
        color: black;
        text-align: center;
        .tda_form_sp1 {
          cursor: pointer;
          color: $second-color;
        }
        &.tda_form_p2 {
          margin-top: 4em;
          // background-color: aquamarine;
        }
        &.tda_form_p3 {
          margin-top: 3em;
          width: 75%;
          // background-color: aquamarine;
        }
      }
      .tda_modal_imageContainer {
        height: 120px;
        width: 120px;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 1680px) {
  .tda_modal {
    .IntrnationalPhoneInput {width: 70%;}
    .tda_modal_content {
      // align-items: center;
      width: 70%;
      // justify-content: center;
      .tda_sec1 {
        padding: 60px 20px 10px 10px;
        .tda_sec1_p1 {
          font-size: 26px;
        }
        .tda_sec1_p2 {
          font-size: 18px;
        }
      }
      // .tda_form {
      // }
      .tda_form {
        .MuiTextField-root {
          width: 56%;
          // .MuiOutlinedInput-input {
          // }
          .MuiOutlinedInput-notchedOutline {
            .PrivateNotchedOutline-legendLabelled-228 {
              span {
                color: $theme-color;
              }
            }
          }

          .tda_btn1 {
            font-size: 16px;
          }
          .tda_form_p1 {
            font-size: 18px;
          }
        }
        .tda_span1 {
          width: 53%;
        }
      }
    }
  }
}

@media (max-width: 1366px) {
  .tda_modal {
.IntrnationalPhoneInput {
  width: 64% !important;
}
    .tda_modal_content {
      // align-items: center;
      width: 80%;
      // justify-content: center;
      .tda_sec1 {
        .tda_sec1_p1 {
          font-size: 26px;
        }
        .tda_sec1_p2 {
          font-size: 16px;
        }
      }
      .tda_form {
        .MuiTextField-root {
          width: 64%;
          .MuiOutlinedInput-input {
          }
          .MuiOutlinedInput-notchedOutline {
            .PrivateNotchedOutline-legendLabelled-228 {
              span {
                color: $theme-color;
              }
            }
          }
        }
        .tda_span1 {
          width: 62%;
        }
        .tda_btn1 {
          margin-top: 3em;
          font-size: 16px;
        }
        .tda_form_p1 {
          margin-top: 3em;
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 1080px) {
  .tda_modal {
// .IntrnationalPhoneInput {
//   width: 74%;
// }
    .tda_modal_content {
      // align-items: center;
      width: 74%;
      // justify-content: center;
      .tda_sec1 {
        width: 40%;
        .tda_sec1_p1 {
          padding-left: 20px;
          font-size: 20px;
        }
        .tda_sec1_p2 {
          padding-left: 20px;
          font-size: 16px;
        }
        .tda_sec1_imageContainer {
          // width: 100%;
          img {
            width: 100%;
          }
        }
      }
      .tda_form {
.IntrnationalPhoneInput{
  width: 64% !important;
}
        .MuiTextField-root {
          width: 64%;
          // .MuiOutlinedInput-input {
          // }
          .MuiOutlinedInput-notchedOutline {
            .PrivateNotchedOutline-legendLabelled-228 {
              span {
                color: $theme-color;
              }
            }
          }
        }
        .tda_span1 {
          width: 62%;
        }
        .tda_btn1 {
          margin-top: 2em;
          font-size: 16px;
        }
        .tda_form_p1 {
          margin-top: 2em;
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .tda_modal {
    .tda_modal_content {
      // align-items: center;
      flex-direction: column;
      width: 74%;
      // justify-content: center;
      .tda_sec1 {
        width: 100%;
        padding: 20px;
        .tda_sec1_p1 {
          padding-left: 0px;
          font-size: 18px;
        }
        .tda_sec1_p2 {
          padding-left: 00px;
          font-size: 14px;
        }
        .tda_sec1_imageContainer {
          display: none;
          width: 100%;
          img {
            width: 80%;
          }
        }
      }
      .tda_form {
        .IntrnationalPhoneInput {width: 90% !important}
        .MuiTextField-root {
          width: 90% ;
          .MuiOutlinedInput-input {
          }
          .MuiOutlinedInput-notchedOutline {
            .PrivateNotchedOutline-legendLabelled-228 {
              span {
                color: $theme-color;
              }
            }
          }
        }
        .tda_span1 {
          width: 88%;
        }
        .tda_btn1 {
          margin-top: 2em;
          font-size: 14px;
        }
        .tda_form_p1 {
          margin-top: 2em;
          font-size: 14px;
        }
      }
    }
  }
}
